import React from 'react';
import ClipboardMinor1 from '../../../../../images/AllDoneImg.png';
import {EmptyState, LegacyStack} from '@shopify/polaris';
import './doneCard.css';

const DoneCard = () => {
  return (
    <LegacyStack vertical alignment='center' distribution='fill'>
      <p className='DoneCard__title'>
        All done!
      </p>
      <EmptyState
        image={ClipboardMinor1}
        fullWidth
      >
        <p className='DoneCard__content'>
          Your selected checkout.liquid upsells have been copied over to a Checkout
          Extension. You can now archive the checkout.liquid upsells.
        </p>
      </EmptyState>
    </LegacyStack>
  );
};

export default DoneCard;